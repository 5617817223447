export const projectsData = [
  {
    title: "Top-Designs3D",
    technologies: ["wordpress", "html", "css", "javascript", "php"],
    description: "Site e-commerce pour une entreprise de modélisation et impression 3D.",
    features: [
      "Juste un site e-commerce."
    ],
    image: "/img/topdesigns3d.gif",
    link: "https://top-designs3d.com"
  },
  {
    title: "Teeks-Dev",
    technologies: ["wordpress", "html", "css", "javascript", "php"],
    description: "Site e-commerce pour un moddeur de jeux vidéo et créateur d'outils.",
    features: [
      "Système de whitelist d'ip pour les mods achetés."
    ],
    image: "/img/teeks-dev.gif",
    link: "https://teeks-dev.com"
  },
  {
    title: "Application de calcul de coût pour l'impression 3D",
    technologies: ["Electron", "html", "css", "javascript"],
    description: "Application de calcul de coût pour l'impression 3D.",
    features: [
      "Calcul des coûts d'impression globaux.",
      "Estimation du prix de vente.",
      "Configuration personnalisable."
    ],
    image: "/img/calcul_print_3d.png",
    link: null
  },
  {
    title: "Application de facturation",
    technologies: ["Electron", "html", "css", "javascript"],
    description: "Permet de générer des factures et devis pour des clients de façons simplifié, mise en place de cette solution pour une micro entreprise.",
    features: [
      "Génération de devis et factures en pdf, juste en remplissant les champs.",
      "Sauvegarde des devis et factures dans des fichiers json pour pouvoir les rechargé facilement plus tard.",
      "Interface utilisateur simple et intuitive."
    ],
    image: "/img/facturation_app.gif",
    link: null
  },
  {
    title: "Glive city",
    technologies: ["React", "html", "css", "javascript"],
    description: "Site vitrine pour un serveur GTA V RP, avec une interface moderne et intuitive.",
    features: [
      "Tout se qu'ont attend d'un site vitrine pour présentez un serveur GTA V RP, comment rejoindre le serveur, les règles ...",
      "Possibilité de rejoindre le serveur via un click sur un bouton.",
      "Interface moderne et intuitive."
    ],
    image: "/img/glivecity.gif",
    link: "https://www.g-live-city.rh-web.fr"
  }
]; 