import React, { useState, useEffect } from 'react';
import '../styles/Projects.css';
import ProjectPopup from './projectPopup';
import { projectsData } from '../data/projectsData';
import { useLanguage } from '../context/LanguageContext';
import { getTranslation } from '../data/translation';

function Projects() {
  const { language } = useLanguage();
  const [stats, setStats] = useState({
    totalProjects: 0,
    technologies: new Map()
  });
  const [selectedProject, setSelectedProject] = useState(null);
  

  useEffect(() => {
    const projectCards = document.querySelectorAll('.project-card');
    const tags = document.querySelectorAll('.tag');
    const techCount = new Map();

    tags.forEach(tag => {
      const tech = tag.textContent;
      techCount.set(tech, (techCount.get(tech) || 0) + 1);
    });

    setStats({
      totalProjects: projectCards.length,
      technologies: techCount
    });
  }, []);

  const formatTechStats = () => {
    return Array.from(stats.technologies.entries())
      .map(([tech, count]) => `${tech} (${count})`)
      .join(', ');
  };

  return (
    <section className="projects" id="projects">
      <div className="projects-container">
        <div className="projects-content">
          <h1>{getTranslation(language, 'projects.title1')} <span className="highlight">{getTranslation(language, 'projects.title2')}</span></h1>
          <h2>Portfolio</h2>

          <div className="terminal-window">
            <div className="terminal-header">
              <span className="terminal-button"></span>
              <span className="terminal-button"></span>
              <span className="terminal-button"></span>
              <div className="terminal-title">projects.js</div>
            </div>
            <div className="terminal-content">
              <div className="command-line">
                <span className="prompt">$</span> ls ./{getTranslation(language, 'projects.title2')}/
              </div>

              <div className="projects-grid">
                {projectsData.map((project, index) => (
                  <div className="project-card" key={index}>
                    <h3>{project.title}</h3>
                    <div className="project-tags">
                      {project.technologies && project.technologies.map((tech, i) => (
                        <span key={i} className="tag">{tech}</span>
                      ))}
                    </div>
                    <p>{project.description}</p>
                    <div className="project-links">
                      <button className="project-link" onClick={() => setSelectedProject(project)}>
                        {getTranslation(language, 'projects.detailsProject')}
                      </button>
                      {project.link && (
                        <button 
                          className="project-link" 
                          onClick={() => window.open(project.link, '_blank')}
                        >
                          {getTranslation(language, 'projects.viewProject')}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="command-line">
                <span className="prompt">$</span> cat {getTranslation(language, 'projects.projectsStats.title')}.txt
              </div>
              <div className="response">
                {getTranslation(language, 'projects.projectsStats.totalProjects')}: {stats.totalProjects}<br/>
                {getTranslation(language, 'projects.projectsStats.technologiesUsed')}: {formatTechStats()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedProject && (
        <ProjectPopup 
          project={selectedProject} 
          onClose={() => setSelectedProject(null)} 
        />
      )}
    </section>
  );
}
export default Projects;


