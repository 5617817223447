import React from 'react';
import '../styles/ProjectPopup.css';

const ProjectPopup = ({ project, onClose }) => {
    return (
      <div className="popup-overlay" onClick={onClose}>
        <div className="popup-content" onClick={e => e.stopPropagation()}>
          <button className="close-button" onClick={onClose}>×</button>
          
          <div className="popup-header">
            <h2>{project.title}</h2>
            <div className="project-tags">
              {project.technologies && project.technologies.map((tech, index) => (
                <span key={index} className="tag">{tech}</span>
              ))}
            </div>
          </div>

        <div className="popup-body">
          <div className="project-description">
            <p>{project.description}</p>
          </div>
          
          {project.image && (
            <div className="project-image">
              <img 
                src={project.image} 
                alt={project.title} 
                onError={(e) => {
                  console.error('Erreur de chargement de l\'image:', project.image);
                  e.target.style.display = 'none';
                }}
              />
            </div>
          )}

          <div className="project-details">
            <h3>Caractéristiques principales :</h3>
            <ul>
              {project.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="popup-footer">
          
        </div>
      </div>
    </div>
  );
};

export default ProjectPopup;