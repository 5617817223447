import React, { useEffect, useState } from 'react';
import './PageTransition.css';

const PageTransition = ({ path }) => {
  const [command, setCommand] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    setCommand('');
    setIsTyping(true);
    
    const pageCommand = `cd ~/${path.replace('/', '') || 'home'}`;
    let currentIndex = 0;
    let typingInterval;

    const typeCommand = () => {
      if (currentIndex < pageCommand.length) {
        setCommand(pageCommand.substring(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setIsTyping(false);
      }
    };

    typingInterval = setInterval(typeCommand, 100);

    return () => {
      clearInterval(typingInterval);
    };
  }, [path]);

  return (
    <div className="page-transition">
      <div className="terminal-popup">
        <div className="terminal-header">
          <div className="terminal-buttons">
            <span className="terminal-button"></span>
            <span className="terminal-button"></span>
            <span className="terminal-button"></span>
          </div>
          <div className="terminal-title">terminal</div>
        </div>
        <div className="terminal-content">
          <span className="prompt">logan-dev@portfolio:~$</span> {command}
          {isTyping && <span className="cursor"></span>}
          {!isTyping && <span className="command-end">▋</span>}
        </div>
      </div>
    </div>
  );
};

export default PageTransition; 