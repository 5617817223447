import React from 'react';
import CodeAnimation from './CodeAnimation';
import { useLanguage } from '../context/LanguageContext';
import { getTranslation } from '../data/translation';
import './Hero.css';

function Hero({ onNavigate }) {
  const { language } = useLanguage();

  const handleProjectClick = (e) => {
    e.preventDefault();
    onNavigate('/projects');
  };

  return (
    <section className="hero" id="home">
      <div className="hero-container">
        <h1>{getTranslation(language, 'hero.greeting')} <span className="highlight">Logan DELMAIRE</span></h1>
        <h2>{getTranslation(language, 'hero.role')}</h2>
        <p>{getTranslation(language, 'hero.description')}</p>
        <a href="/projects" 
           className="cta-button"
           onClick={handleProjectClick}>
          {getTranslation(language, 'hero.ctaButton')}
        </a>
      </div>
      <div className="code-container">
        <CodeAnimation />
      </div>
    </section>
  );
}

export default Hero;