import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Install.css';
import { useLanguage } from '../context/LanguageContext';

const Install = () => {
  const [started, setStarted] = useState(false);
  const [currentCommand, setCurrentCommand] = useState(0);
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);
  const navigate = useNavigate();
  const { changeLanguage } = useLanguage();
  const [commandsList, setCommandsList] = useState([
    'npm install Logan-DEV@portfolio',
    'Installing components...',
    'Configuring environment...',
    'Loading assets...',
    'Selecting language...',
    null,
    'npm start',
    'Starting development server...',
    'Done! Redirecting...'
  ]);

  useEffect(() => {
    if (started && currentCommand < commandsList.length) {
      if (currentCommand === 4) {
        setShowLanguageSelect(true);
        return;
      }

      if (currentCommand !== 4) {
        const timer = setTimeout(() => {
          setCurrentCommand(prev => prev + 1);
        }, 1500);
        return () => clearTimeout(timer);
      }
    }

    if (started && currentCommand === commandsList.length) {
      const redirectTimer = setTimeout(() => {
        navigate('/home');
      }, 1000);
      return () => clearTimeout(redirectTimer);
    }
  }, [started, currentCommand, navigate, commandsList.length]);

  const handleLanguageSelect = (lang) => {
    changeLanguage(lang);
    setCommandsList(prev => {
      const newCommands = [...prev];
      newCommands[5] = `set language ${lang}`;
      return newCommands;
    });
    setShowLanguageSelect(false);
    setCurrentCommand(prev => prev + 1);
  };

  return (
    <div className="install-container">
      {!started ? (
        <button className="start-button" onClick={() => setStarted(true)}>
          Start
        </button>
      ) : (
        <div className="terminal">
          <div className="terminal-header">
            <div className="terminal-button red"></div>
            <div className="terminal-button yellow"></div>
            <div className="terminal-button green"></div>
          </div>
          <div className="terminal-content">
            {commandsList.slice(0, currentCommand + 1).map((cmd, index) => (
              <div key={index} className="command-line">
                <span className="prompt">$ </span>
                <span className="command">{cmd}</span>
                {index === currentCommand && <span className="cursor">_</span>}
              </div>
            ))}
            {showLanguageSelect && (
              <div className="language-select">
                <button onClick={() => handleLanguageSelect('fr')}>Français</button>
                <button onClick={() => handleLanguageSelect('en')}>English</button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Install; 