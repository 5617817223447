import React from 'react';
import '../styles/home.css';
import Hero from '../components/Hero.jsx';

function Home({ onNavigate }) {
  return (
    <div className="home">
      <Hero onNavigate={onNavigate} />
    </div>
  );
}

export default Home;
