export const translations = {
    fr: {
      navbar: {
        home: "Accueil",
        about: "À propos",
        projects: "Projets",
        contact: "Contact"
      },
      hero: {
        greeting: "Bonjour, je suis",
        role: "Développeur",
        description: "J'utilise mes compétences pour simplifier mes tâches et celles des autres",
        ctaButton: "Voir mes projets"
      },
      navigation: {
        home: "Accueil",
        about: "À propos",
        projects: "Projets",
        contact: "Contact"
      },
      about: {
        title1: "À propos",
        title2: "de moi",
        role: "Développeur Full Stack",
        description: "Développeur passionné par le web et la création de solutions innovantes, je conçois des sites et applications intuitives, ainsi que des outils pour simplifier les tâches informatiques récurrentes. Pendant mon temps libre, j'aime aussi développer des mods pour les jeux vidéo, combinant technologie et créativité pour enrichir l'expérience de jeu.",
        education: {
          title: "Études",
          step1: "Diplôme du Baccalauréat Scientifique — 2019",
          step2: "Formation de concepteur web (ESECAD) — Niveau 3, 2019-2022",
          step3: "Autodidacte — Continuellement"
        },
        mainSkills: "Compétences-principales",
        funSkills: "Compétences-de-loisir",
        experience: {
          title: "Expériences",
          step1: "Stage chez Quadra Informatique - Découverte, décembre 2016",
          step2: "Stage chez Fas Marquage - Introduction au graphisme, 2018",
          step3: "Stage chez Fas Marquage - Refonte du site internet, 2020",
          step4: "Développeur indépendant - Depuis 2022"
        },
        hobbies: {
          title: "Passions",
          step1: "Écrire du code pour simplifier et automatiser des tâches récurrentes",
          step2: "Créer des mods de jeux vidéo",
          step3: "Apprendre en permanence de nouveaux langages de programmation",
          step4: "Modélisation et impression 3D",
          step5: "Sports mécaniques"
        }
      },
      projects: {
        title1: "Mes",
        title2: "Projets",
        detailsProject: "Détails",
        viewProject: "Voir",
        projectsStats: {
          title: "Statistiques-des-projets",
          totalProjects: "Total de projets",
          technologiesUsed: "Technologies utilisées"
        }
      },
      contact: {
        title1: "Contactez",
        title2: "moi",
        subtitle: "Prendre contact",
        form: {
          title: "Formulaire-de-contact",
          subject_label: "Sujet",
          subject_input: "Votre sujet...",
          name_label: "Nom",
          name_input: "Votre nom...",
          email_label: "Email",
          email_input: "Votre email...",
          message_label: "Message",
          message_input: "Votre message...",
          send: "Envoyer",
          sending: "Envoi en cours...",
          success: "Message envoyé avec succès !",
          error: "Erreur lors de l'envoi du message."
        }
      }
    },
    en: {
      navbar: {
        home: "Home",
        about: "About",
        projects: "Projects",
        contact: "Contact"
      },
      hero: {
        greeting: "Hi there, I'm",
        role: "Developer",
        description: "I use my skills to simplify my tasks and those of others",
        ctaButton: "See my projects"
      },
      navigation: {
        home: "Home",
        about: "About",
        projects: "Projects",
        contact: "Contact"
      },
      about: {
        title1: "About",
        title2: "me",
        description: "As a developer with a passion for the web and the creation of innovative solutions, I design intuitive websites and applications, as well as tools to simplify recurring IT tasks. In my spare time, I also like to code mods for video games, combining technology and creativity to enrich the gaming experience.",
        education: {
          title: "Education",
          step1: "High School Diploma in Science — 2019",
          step2: "Web Designer Training (ESECAD) — Bachelor’s Level 3, 2019-2022",
          step3: "Self-Taught Learning — Continuously"
        },
        mainSkills: "Main-Skills",
        funSkills: "Fun-Skills",
        experience: {
          title: "Experience",
          step1: "Internship at Quadra Informatique - Discovery, December 2016",
          step2: "Internship at Fas Marking - Introduction to Graphic Design, 2018",
          step3: "Internship at Fas Marking - Website Redesign, 2020",
          step4: "Freelance Developer - Since 2022"
        },
        hobbies: {
          title: "Hobbies",
          step1: "Write code to simplify and automate recurring tasks",
          step2: "Create video game mods",
          step3: "Continuously learn new programming languages",
          step4: "3D modeling and printing",
          step5: "Motor sports"
        }
      },
      projects: {
        title1: "My",
        title2: "Projects",
        detailsProject: "Details",
        viewProject: "View",
        projectsStats: {
          title: "Projects-Stats",
          totalProjects: "Total projects",
          technologiesUsed: "Technologies used"
        }
      },
      contact: {
        title1: "Contact",
        title2: "me",
        subtitle: "Get in touch",
        form: {
          title: "Contact-Form",
          subject_label: "Subject",
          subject_input: "Your subject...",
          name_label: "Name",
          name_input: "Your name...",
          email_label: "Email",
          email_input: "Your email...",
          message_label: "Message",
          message_input: "Your message...",
          send: "Send",
          sending: "Sending...",
          success: "Message sent successfully!",
          error: "Error sending message."
        }
      }
    }
  };
  
  export const getTranslation = (lang, key) => {
    const keys = key.split('.');
    let translation = translations[lang];
    
    for (const k of keys) {
      if (translation && translation[k]) {
        translation = translation[k];
      } else {
        return key;
      }
    }
    
    return translation;
  };